import React from 'react'
import { Helmet } from 'react-helmet';
import Backscroller from '../../component/BackScroller/Backscroller'
import Header from '../../component/Header/Header'
import Footer from '../../component/Footer/Footer'
import PageHeader from '../../component/PageHeader/Pageheader'
import StatsSection from '../../component/Stats/Stats'
import RevealCards from '../../component/RevealCards/Revealcards'
import ProductFeatures from '../../component/ProductFeatures/ProductFeatures'
import { BouncyCardsFeatures } from '../../component/BouncyCardFeature/BouncyCardFeature'
import { Hero } from '../../component/ServicesHero/Hero'
import BasicFAQ from '../../component/Faq/Faq'

const Services = () => {
  return (
    <>
    <Helmet>
        <title>Our Services | My Care Trading</title>
        <meta name="description" content="Explore our comprehensive range of services, including import, export, consulting, and environmental solutions for the heavy metal scrap industry. Tailored solutions for your business needs." />
        <meta name="keywords" content="services, import, export, consulting, environmental solutions, scrap metal" />
        <meta name="author" content="My Care Trading" />
        <meta property="og:title" content="Home - My Care Trading" />
        <meta property="og:description" content="Welcome to My Care Trading. Explore our global import and export services, sustainable recycling solutions, and expertise in heavy metal scrap trading." />
        <meta property="og:image" content="https://mycaretrading.com/wp-content/uploads/2024/04/image-1-5-2048x1866.png" />
        <meta property="og:url" content="https://mycaretrading.com" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    <Header/>
    <PageHeader/>
    <Hero/>
    <BouncyCardsFeatures/>
    <ProductFeatures/>
    {/* <RevealCards/> */}
    {/* <StatsSection/> */}
    {/* <h1>Our Services</h1> */}
    {/* <Backscroller/> */}
    <BasicFAQ/>
    <Footer/>
    </>
  )
}

export default Services
