import React from "react";
import { motion } from "framer-motion";

const BounceCard = ({ className, title, imageSrc, imageAlt, children }) => {
  return (
    <motion.div
      whileHover={{ scale: 0.95, rotate: "-1deg" }}
      className={`group relative min-h-[400px] cursor-pointer overflow-hidden rounded-2xl bg-slate-100  ${className}`}
    >
      {/* <CardTitle>{title}</CardTitle> */}
      <CardImage src={imageSrc} alt={imageAlt} />
      {children}
    </motion.div>
  );
};

const CardTitle = ({ children }) => {
  return (
    <h3 className="text-center text-2xl font-semibold mb-4">{children}</h3>
  );
};

const CardImage = ({ src, alt }) => {
  return (
    <img className="w-full h-64 object-cover rounded-t-2xl" src={src} alt={alt} />
  );
};

export default BounceCard;
