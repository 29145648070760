import logo from './logo.svg';
import './App.css';
import Aboutus from './Pages/AboutUs/Aboutus';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Pages/Home/Home';
import Header from './component/Header/Header';
import { Footer } from './component/Footer/Footer';
import Services from './Pages/Services/Services';
import Contact from './component/Contact/Contact';
import Alert from './component/Alert/Alert';
import Timelines from './component/Timelines/Timelines';
import Blogs from './component/Blog/Blogs';
import ReadBlog from './component/Blog/ReadBlog ';
import Blog from './Pages/Blog/Blog';

function App() {
  return (
    <div className="App">
    <Router >
        
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/About" element={<Aboutus />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Alert" element={<Alert />} />
        <Route path="/Timelines" element={<Timelines/>} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blog/:id" element={<ReadBlog />} />
      
  





      </Routes>

    </Router>
  </div>
  );
}

export default App;
