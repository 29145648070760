import { motion } from "framer-motion";
import { useState } from "react";
import {
  FiArrowUp,
  FiChevronLeft,
  FiChevronRight,
  FiLink,
  FiTarget,
  FiTool,
  FiUpload,
} from "react-icons/fi";

const CollapseCardFeatures = () => {
  const [position, setPosition] = useState(0);

  const shiftLeft = () => {
    if (position > 0) {
      setPosition((pv) => pv - 1);
    }
  };

  const shiftRight = () => {
    if (position < features.length - 1) {
      setPosition((pv) => pv + 1);
    }
  };

  return (
    <section className="overflow-hidden bg-white px-3 py-12">
      <div className="mx-auto max-w-6xl">
        <div className="mb-8 flex justify-between gap-4">
          <h2 className="text-4xl font-bold leading-[1.2] md:text-5xl">
            We're good. <span className="text-indigo-400">Here's why.</span>
          </h2>
          <div className="flex gap-2">
            <button
              className="h-fit bg-indigo-400 p-4 text-2xl text-white transition-colors hover:bg-neutral-700"
              onClick={shiftLeft}
            >
              <FiChevronLeft />
            </button>
            <button
              className="h-fit bg-indigo-400 p-4 text-2xl text-white transition-colors hover:bg-neutral-700"
              onClick={shiftRight}
            >
              <FiChevronRight />
            </button>
          </div>
        </div>
        <div className="flex gap-4">
          {features.map((feat, index) => (
            <Feature {...feat} key={index} position={position} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

const Feature = ({ position, index, title, description, Icon }) => {
  const translateAmt =
    position >= index ? index * 100 : index * 100 - 100 * (index - position);

  return (
    <motion.div
      animate={{ x: `${-translateAmt}%` }}
      transition={{
        ease: "easeInOut",
        duration: 0.35,
      }}
      className={`relative flex min-h-[250px] w-10/12 max-w-lg shrink-0 flex-col justify-between overflow-hidden p-8 shadow-lg md:w-3/5 ${
        index % 2 ? "bg-indigo-400 text-white" : " bg-white"
      }`}
    >
      <h3 className="mb-8 text-3xl font-bold">{title}</h3>
      <p>{description}</p>
    </motion.div>
  );
};

export default CollapseCardFeatures;

const features = [
  {
    title: "Trust and Integrity",
    Icon: FiUpload,
    description:
      "We build long-term relationships based on trust and consistent performance, ensuring peace of mind for our clients!",
  },
  {
    title: "Transparent Pricing",
    Icon: FiArrowUp,
    description:
      "We offer competitive and clear pricing with no hidden fees, ensuring you get the best value!",
  },
  {
    title: "Reliable Collection",
    Icon: FiTarget,
    description:
      "We provide hassle-free scrap collection services directly from your location, whether it’s your home, office, factory, or company.",
  },
  {
    title: "Comprehensive Services",
    Icon: FiLink,
    description:
      "From iron and aluminium to electronic and battery scrap, we handle a wide variety of materials, ensuring a one-stop solution for all your scrap needs.",
  },
  {
    title: "Customer-Centric Approach",
    Icon: FiTool,
    description:
      " Our services are tailored to meet your specific requirements, ensuring convenience and satisfaction.",
  },
];