import React from 'react'
import bannerImage from '../../Assets/banneraboutus.png'

const Banner = () => {
  return (
    <div
    class="relative h-[400px] overflow-hidden  bg-cover bg-[50%] bg-no-repeat" style={{ backgroundImage: `url(${bannerImage})`, backgroundSize: 'cover', backgroundPosition: '50%' }}>
    <div
      class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-black/60 bg-fixed">
      <div class="flex h-full items-center justify-center">
        <div class="px-6 text-center text-white md:px-12">
          <h1 class="mb-6 text-7xl font-bold">AboutUs</h1>
          <h3 class="mb-8 text-3xl font-bold">My Care Trading</h3>
          <button
            type="button"
            class="inline-block rounded border-2 border-neutral-50 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-300 hover:text-neutral-200 focus:border-neutral-300 focus:text-neutral-200 focus:outline-none focus:ring-0 active:border-neutral-300 active:text-neutral-200 dark:hover:bg-neutral-600 dark:focus:bg-neutral-600"
            data-twe-ripple-init
            data-twe-ripple-color="light">
            Call to action
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Banner
