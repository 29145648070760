import React from 'react';

const Testimonial = () => {
  return (
    <section
      className="rounded-md p-6 text-center shadow-lg md:p-12 md:text-left"
      style={{ backgroundImage: "url('https://tecdn.b-cdn.net/img/Photos/Others/background2.jpg')" }}>
      <div className="flex justify-center">
        <div className="max-w-3xl">
          <section className="text-neutral-700 dark:text-neutral-300">
            <div className="mx-auto text-center md:max-w-xl lg:max-w-3xl">
              <h3 className="mb-6 text-3xl  text-black font-bold">Reviews</h3>
              <p className="mb-6 text-black pb-2 md:mb-12 md:pb-0">
              We value our customers and their feedback. Here's what they have to say about My Care Trading:
              </p>
            </div>

            <div className="grid gap-6 text-center md:grid-cols-3">
              <div className="flex flex-col h-full">
                <div className="block flex-1 rounded-lg bg-white shadow-lg dark:bg-neutral-700 dark:shadow-black/30">
                  <div className="h-28 overflow-hidden rounded-t-lg bg-[#9d789b]"></div>
                  <div className="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white dark:border-neutral-800 dark:bg-neutral-800">
                    <img src="https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp" />
                  </div>
                  <div className="p-6 flex-1 flex flex-col">
                    <h4 className="mb-4 text-2xl font-semibold">Maria Smantha</h4>
                    <hr />
                    <p className="mt-4 flex-1">
                      <span className="inline-block pe-2 [&>svg]:w-5">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
                          <path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z" />
                        </svg>
                      </span>
                      As a small business owner, timely deliveries are crucial for my reputation. My Care Trading has been a game-changer for us. Their quick shipping times and affordable products have made our job easy.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col h-full">
                <div className="block flex-1 rounded-lg bg-white shadow-lg dark:bg-neutral-700 dark:shadow-black/30">
                  <div className="h-28 overflow-hidden rounded-t-lg bg-[#7a81a8]"></div>
                  <div className="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white dark:border-neutral-800 dark:bg-neutral-800">
                    <img src="https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(2).webp" />
                  </div>
                  <div className="p-6 flex-1 flex flex-col">
                    <h4 className="mb-4 text-2xl font-semibold">Lisa Cudrow</h4>
                    <hr />
                    <p className="mt-4 flex-1">
                      <span className="inline-block pe-2 [&>svg]:w-5">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
                          <path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z" />
                        </svg>
                      </span>
                      I’ve been using My Care Trading for over a year now, and I’m consistently impressed by their reliability.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col h-full">
                <div className="block flex-1 rounded-lg bg-white shadow-lg dark:bg-neutral-700 dark:shadow-black/30">
                  <div className="h-28 overflow-hidden rounded-t-lg bg-[#7a81a8]"></div>
                  <div className="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white dark:border-neutral-800 dark:bg-neutral-800">
                    <img src="https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(3).webp" />
                  </div>
                  <div className="p-6 flex-1 flex flex-col">
                    <h4 className="mb-4 text-2xl font-semibold">John Smith</h4>
                    <hr />
                    <p className="mt-4 flex-1">
                      <span className="inline-block pe-2 [&>svg]:w-5">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
                          <path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z" />
                        </svg>
                      </span>
                      They truly care about my needs and go above and beyond to ensure satisfaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
