import React from 'react'
import Blogs from '../../component/Blog/Blogs'
import Header from '../../component/Header/Header'
import Footer from '../../component/Footer/Footer'

const Blog = () => {
  return (
    <div>
        <Header/>
      <Blogs/>
      <Footer/>
    </div>
  )
}

export default Blog
