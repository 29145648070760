import React from "react";
import BounceCard from "./BounceCard";
import exporting from '../../Assets/export-1 2.png'
import importing from '../../Assets/Import.png'
import consulting from '../../Assets/Consulting.png'
import Enviormental from '../../Assets/Environmental.png'
import TruckButton from "../ButtonOder/TruckButton";
import ButtonVechile from "../ButtonOder/ButtonVechile";

export const BouncyCardsFeatures = () => {
  return (
    <section className="mx-auto max-w-7xl px-5 py-12 text-slate-800">
      <div className="mb-8 flex flex-col items-start justify-between gap-4 md:flex-row md:items-end md:px-8">
        <h2 className="max-w-lg text-4xl font-bold md:text-5xl">
          Services
          <span className="text-slate-400"> we provide </span>
        </h2>
      </div>
      <div className="mb-4 grid grid-cols-12 gap-4">
        <BounceCard 
          className="col-span-12 md:col-span-6"
          title="Do a thing"
          imageSrc={exporting}
          imageAlt="Do a thing"
        >
          <div className="absolute bottom-0 left-4 right-4 top-30 translate-y-8 rounded-t-2xl bg-gradient-to-br from-violet-400 to-indigo-400 p-9 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]">
            <span className="block text-center font-semibold text-indigo-50">
            • Procurement of Quality Metal Scrap
            </span>
            <span className="block text-center font-semibold text-indigo-50">
            • Customs Clearance and Logistics Management
            </span>
            <span className="block text-center font-semibold text-indigo-50">
            • Compliance with International Trade Regulations
            </span>
           
            <ButtonVechile/>

          </div>
        </BounceCard>
        <BounceCard 
          className="col-span-12 md:col-span-6"
          title="Do another thing"
          imageSrc={importing}
          imageAlt="Do another thing"
        >
          <div className="absolute bottom-0 left-4 right-4 top-30 translate-y-8 rounded-t-2xl bg-gradient-to-br from-amber-400 to-orange-400 p-9 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]">
          <span className="block text-center font-semibold text-indigo-50">
          • Market Analysis and Pricing Strategies
            </span>
            <span className="block text-center font-semibold text-indigo-50">
            • Supply Chain Optimization
            </span>
            <span className="block text-center font-semibold text-indigo-50">
            • Export Documentation and Shipment Handling
            </span>
            <ButtonVechile/>

          </div>
        </BounceCard>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <BounceCard 
          className="col-span-12 md:col-span-6"
          title="And this too"
          imageSrc={Enviormental}
          imageAlt="And this too"
        >
          <div className="absolute bottom-0 left-4 right-4 top-30 translate-y-8 rounded-t-2xl bg-gradient-to-br from-green-400 to-emerald-400 p-9 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]">
          <span className="block text-center font-semibold text-indigo-50">
          • Market Research and Analysis
            </span>
            <span className="block text-center font-semibold text-indigo-50">
            • Strategic Planning and Business Development
            </span>
            <span className="block text-center font-semibold text-indigo-50">
            • Regulatory Compliance and Risk Management
            </span>
            <ButtonVechile/>
          </div>
        </BounceCard>
        <BounceCard 
          className="col-span-12 md:col-span-6"
          title="And finally this"
          imageSrc={consulting}
          imageAlt="And finally this"
        >
          <div className="absolute bottom-0 left-4 right-4 top-30 translate-y-8 rounded-t-2xl bg-gradient-to-br from-pink-400 to-red-400 p-9 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]">
          <span className="block text-center font-semibold text-indigo-50">
          •Sustainable Recycling Practices
            </span>
            <span className="block text-center font-semibold text-indigo-50">
            
•Waste Management Consultation
            </span>
            <span className="block text-center font-semibold text-indigo-50">
            •Green Initiatives and Impact Assessment
            </span>
            <ButtonVechile/>

          </div>
        </BounceCard>
      </div>
    </section>
  );
};

export default BouncyCardsFeatures;
