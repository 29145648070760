import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";
import useMeasure from "react-use-measure";

const BasicFAQ = () => {
  return (
    <div className="px-4 py-12">
      <div className="mx-auto max-w-3xl">
      <h1 className="mb-4 text-center text-5xl font-bold">
          Frequently Asked Questions
        </h1>
        <Question title="What types of scrap metal do you handle?" defaultOpen>
          <p>
            My Care Trading handles a wide range of scrap metals, including ferrous and non-ferrous metals such as steel, aluminum, copper, and more. We provide comprehensive solutions for both importing and exporting these materials.
          </p>
        </Question>
        <Question title="How do you ensure the quality of the scrap metal?" defaultOpen>
          <p>
            We adhere to strict quality control processes, including thorough inspections and testing of all materials. Our team ensures that all scrap metal meets industry standards and customer specifications.
          </p>
        </Question>
        <Question title="What services do you offer related to scrap metal trading?">
          <p>
            We offer a full suite of services, including the import and export of scrap metal, consulting services, and environmental solutions to ensure responsible recycling and disposal of materials.
          </p>
        </Question>
        <Question title="How does My Care Trading contribute to sustainability?">
          <p>
            My Care Trading is committed to eco-friendly practices by promoting recycling and reducing waste. Our sustainable solutions help minimize the environmental impact of scrap metal disposal and support a circular economy.
          </p>
        </Question>
        <Question title="What sets My Care Trading apart from other scrap metal trading companies?">
          <p>
            My Care Trading stands out due to our extensive industry experience, commitment to quality, and customer-centric approach. We provide tailored solutions, competitive pricing, and reliable service to meet the unique needs of our clients.
          </p>
        </Question>
        <Question title="How can I get started with My Care Trading?">
          <p>
            To get started, simply contact us through our website or call our customer service team. We will discuss your specific requirements and provide customized solutions to meet your scrap metal trading needs.
          </p>
        </Question>
        <Question title="How is My Care Trading the best choice for scrap metal trading?">
          <p>
            My Care Trading offers unparalleled expertise, a broad range of services, and a strong commitment to sustainability. Our focus on quality, customer satisfaction, and eco-friendly practices makes us the best choice for your scrap metal trading needs.
          </p>
        </Question>
      </div>
    </div>
  );
};

const Question = ({ title, children, defaultOpen = false }) => {
  const [ref, { height }] = useMeasure();
  const [open, setOpen] = useState(defaultOpen);

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      className="border-b-[1px] border-b-slate-300"
    >
      <button
        onClick={() => setOpen((pv) => !pv)}
        className="flex w-full items-center justify-between gap-4 py-6"
      >
        <motion.span
          variants={{
            open: {
              color: "rgba(3, 6, 23, 0)",
            },
            closed: {
              color: "rgba(3, 6, 23, 1)",
            },
          }}
          className="bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text text-left text-lg font-medium"
        >
          {title}
        </motion.span>
        <motion.span
          variants={{
            open: {
              rotate: "180deg",
              color: "rgb(124 58 237)",
            },
            closed: {
              rotate: "0deg",
              color: "#030617",
            },
          }}
        >
          <FiChevronDown className="text-2xl" />
        </motion.span>
      </button>
      <motion.div
        initial={false}
        animate={{
          height: open ? height : "0px",
          marginBottom: open ? "24px" : "0px",
        }}
        className="overflow-hidden text-slate-600"
      >
        <p ref={ref}>{children}</p>
      </motion.div>
    </motion.div>
  );
};

export default BasicFAQ;