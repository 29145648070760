import { motion, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FiDollarSign, FiEye, FiPlay, FiSearch } from "react-icons/fi";
import exporting from '../../Assets/about.png'
import exporting1 from '../../Assets/about1.png'
import exporting2 from '../../Assets/about2.png'
import exporting3 from '../../Assets/about3.png'

const Example = () => {
  const [featureInView, setFeatureInView] = useState(features[0]);

  return (
    <section className="relative mx-auto max-w-7xl">
      <SlidingFeatureDisplay featureInView={featureInView} />

      <div className="-mt-[100vh] hidden md:block" />

      {features.map((s) => (
        <Content
          key={s.id}
          featureInView={s}
          setFeatureInView={setFeatureInView}
          {...s}
        />
      ))}
    </section>
  );
};

const SlidingFeatureDisplay = ({ featureInView }) => {
  return (
    <div
      style={{
        justifyContent:
          featureInView.contentPosition === "l" ? "flex-end" : "flex-start",
      }}
      className="pointer-events-none sticky top-0 z-10 hidden h-screen w-full items-center justify-center md:flex"
    >
      <motion.div
        layout
        transition={{
          type: "spring",
          stiffness: 400,
          damping: 25,
        }}
        className="h-fit w-3/5 rounded-xl p-8"
      >
        <ExampleFeature featureInView={featureInView} />
      </motion.div>
    </div>
  );
};

const Content = ({ setFeatureInView, featureInView }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: "-150px",
  });

  useEffect(() => {
    if (isInView) {
      setFeatureInView(featureInView);
    }
  }, [isInView]);

  return (
    <section
      ref={ref}
      className="relative z-0 flex h-fit md:h-screen"
      style={{
        justifyContent:
          featureInView.contentPosition === "l" ? "flex-start" : "flex-end",
      }}
    >
      <div className="grid h-full w-full place-content-center px-4 py-12 md:w-2/5 md:px-8 md:py-8">
        <motion.div
          initial={{ opacity: 0, y: 25 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          <span className="rounded-full bg-indigo-600 px-2 py-1.5 text-xs font-medium text-white">
            {featureInView.callout}
          </span>
          <p className="my-3 text-5xl font-bold">{featureInView.title}</p>
          <p className="text-slate-600">{featureInView.description}</p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 25 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className="mt-8 block md:hidden"
        >
          <ExampleFeature featureInView={featureInView} />
        </motion.div>
      </div>
    </section>
  );
};

const ExampleFeature = ({ featureInView }) => {
  return (
    <div
      className="relative h-96 w-90 rounded-xl shadow-xl bg-cover bg-center"
      style={{ backgroundImage: `url(${featureInView.image})` }}
    >
    
    </div>
  );
};

export default Example;
const features = [
  {
    id: 1,
    callout: "Expertise and Reliability",
    title: "Unmatched Expertise",
    description:
      "With years in the industry, we bring unmatched expertise and reliability to all our transactions. Our team is composed of industry veterans who ensure you receive the best service possible.",
    contentPosition: "r",
    image: exporting,
    Icon: FiEye,
  },
  {
    id: 2,
    callout: "Comprehensive Services",
    title: "Wide Range of Solutions",
    description:
      "From procurement to logistics, we handle all aspects of scrap management. Our services cover a wide variety of materials, ensuring a one-stop solution for all your scrap needs.",
    contentPosition: "l",
    image: exporting1,
    Icon: FiSearch,
  },
  {
    id: 3,
    callout: "Environmental Commitment",
    title: "Sustainable Practices",
    description:
      "We are committed to sustainable recycling practices that help reduce waste and promote environmental conservation. Partner with us to make a positive impact on the planet.",
    contentPosition: "r",
    image: exporting2,
    Icon: FiPlay,
  },
  {
    id: 4,
    callout: "Customer-Centric Approach",
    title: "Your Needs, Our Priority",
    description:
      "Our customer-centric approach ensures that our services are tailored to meet your specific requirements. We prioritize your convenience and satisfaction with transparent pricing and reliable service.",
    contentPosition: "l",
    image: exporting3,
    Icon: FiDollarSign,
  },
];
