import { motion } from 'framer-motion';
import { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useMeasure } from '@react-hookz/web';

const CARD_WIDTH = 350;
const CARD_HEIGHT = 350;
const MARGIN = 20;
const CARD_SIZE = CARD_WIDTH + MARGIN;

const BREAKPOINTS = {
  sm: 640,
  lg: 1024,
};

const items = [

  {
    id: 1,
    url: "https://img.freepik.com/free-photo/dirty-dumped-objects-arrangement_23-2148996942.jpg?t=st=1720108219~exp=1720111819~hmac=7f775584ac06bbdb947f3f47433386cedb7371e0a889b01b95247f8f86443e3c&w=360",
    category: "Mice",
    title: "Aluminium Scrap Buyer",
    description: "Sourced from cans, foils, and frames; recycled for new cans, automotive parts, and building materials.",
  },
  {
    id: 2,
    url: "https://t3.ftcdn.net/jpg/03/76/01/90/240_F_376019027_YYGojOuZ4zWRWnVRRLJWYPLJtUJiqNZF.jpg",
    category: "Keyboards",
    title: "Computer Scrap Buyer",
    description: "Reclaimed from old computers; contains valuable metals like gold and copper, used in electronics manufacturing.",
  },
  {
    id: 3,
    url: "https://img.freepik.com/free-photo/gold-textured-background_53876-15082.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user",
    category: "Monitors",
    title: "Copper Scrap Buyer",
    description: "Collected from wires, plumbing, and electronics; highly recyclable for electrical wiring and industrial machinery.",
  },
  {
    id: 4,
    url: "https://img.freepik.com/free-photo/assortment-dirty-dumped-objects_23-2148996979.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user",
    category: "Chairs",
    title: "Brass Scraps Buyer",
    description: "Obtained from plumbing fixtures and musical instruments; recycled into new brass products and components",
  },
  {
    id: 5,
    url: "https://img.freepik.com/free-photo/high-view-battery-pollution-waste_23-2148764794.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user",
    category: "Lights",
    title: "Battery Scrap Buyer",
    description: "Extracted from old batteries; materials like lead and lithium are recovered for new batteries and other products",
  },
  {
    id: 6,
    url: "https://img.freepik.com/free-photo/beans-market-india_1353-68.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user",
    category: "Desks",
    title: "Electrical Scrap Buyer",
    description: "Includes wires and electrical components; recycled for copper and other metals for industrial use.",
  },
  {
    id: 7,
    url: "https://img.freepik.com/free-photo/beans-market-india_1353-68.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user",
    category: "Desks",
    title: "Electronic Scrap Buyer",
    description: "Gathered from obsolete gadgets; contains valuable metals and components for reuse in new electronics.",
  },  

  {
    id: 8,
    url: "https://img.freepik.com/free-photo/shattered-roof-tiles_1353-251.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user",
    category: "Headphones",
    title: "Lead Scrap Buyer",
    description: "Sourced from lead-acid batteries and pipes; recycled for new batteries, radiation shielding, and construction materials.",
  },
  {
    id: 9,
    url: "https://img.freepik.com/free-photo/dirty-dumped-objects-arrangement_23-2148996942.jpg?t=st=1720108219~exp=1720111819~hmac=7f775584ac06bbdb947f3f47433386cedb7371e0a889b01b95247f8f86443e3c&w=360",
    category: "Mice",
    title: "Iron Scrap Buyer",
    description: "Derived from appliances and construction debris; recycled for steel production, reducing the need for virgin iron ore..",
  },
];

const CardCarousel = () => {
  const [ref, { width }] = useMeasure();
  const [offset, setOffset] = useState(0);

  const CARD_BUFFER =
    width > BREAKPOINTS.lg ? 3 : width > BREAKPOINTS.sm ? 2 : 1;

  const CAN_SHIFT_LEFT = offset < 0;

  const CAN_SHIFT_RIGHT =
    Math.abs(offset) < CARD_SIZE * (items.length - CARD_BUFFER);

  const shiftLeft = () => {
    if (!CAN_SHIFT_LEFT) {
      return;
    }
    setOffset((prev) => prev + CARD_SIZE);
  };

  const shiftRight = () => {
    if (!CAN_SHIFT_RIGHT) {
      return;
    }
    setOffset((prev) => prev - CARD_SIZE);
  };

  return (
    <section className="bg-slate-100" ref={ref}>
      <div className="relative overflow-hidden p-4">
        <div className="mx-auto max-w-6xl">
          <motion.div
            animate={{
              x: offset,
            }}
            className="flex"
          >
            {items.map((item) => {
              return <Card key={item.id} {...item} />;
            })}
          </motion.div>
        </div>

        <>
          <motion.button
            initial={false}
            animate={{
              x: CAN_SHIFT_LEFT ? "0%" : "-100%",
            }}
            className="absolute left-0 top-[60%] z-0 rounded-r-xl bg-slate-100/30 p-3 pl-2 text-4xl text-white backdrop-blur-sm transition-[padding] hover:pl-3"
            onClick={shiftLeft}
          >
            <FiChevronLeft />
          </motion.button>
          <motion.button
            initial={false}
            animate={{
              x: CAN_SHIFT_RIGHT ? "0%" : "100%",
            }}
            className="absolute right-0 top-[60%] z-30 rounded-l-xl bg-slate-100/30 p-3 pr-2 text-4xl text-white backdrop-blur-sm transition-[padding] hover:pr-3"
            onClick={shiftRight}
          >
            <FiChevronRight />
          </motion.button>
        </>
      </div>
    </section>
  );
};

const Card = ({ url, category, title, description }) => {
  return (
    <div
      className="relative shrink-0 cursor-pointer rounded-2xl bg-white shadow-md transition-all hover:scale hover:shadow-xl"
      style={{
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        marginRight: MARGIN,
        backgroundImage: `url(${url})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="absolute inset-0 rounded-2xl bg-gradient-to-b from-black/90 via-black/60 to-black/0 p-6 text-white transition-[backdrop-filter] hover:backdrop-blur-sm">
        <span className="text-xs font-semibold uppercase text-violet-300">
          
        </span>
        <p className="my-2 text-3xl font-bold">{title}</p>
        <p className="text-lg text-slate-300">{description}</p>
      </div>
    </div>
  );
};

export default CardCarousel;
