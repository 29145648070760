import { MotionConfig, motion } from "framer-motion";

const Testimonial = ({ imgSrc, name, title, company, content }) => (
  <MotionConfig
    transition={{
      duration: 0.2,
      ease: "easeInOut",
    }}
  >
    <motion.div
      initial={{
        y: 0,
      }}
      animate={{
        y: -8,
      }}
      exit={{
        y: 0,
      }}
      className="w-full overflow-hidden rounded-lg border-2 bg-white p-8 md:p-12"
    >
      <div className="mb-6 flex items-center gap-6">
        <div className="rounded-lg">
          <motion.img
            initial={{
              rotate: "0deg",
              opacity: 0,
            }}
            animate={{
              rotate: "0deg",
              opacity: 1,
            }}
            exit={{
              rotate: "0deg",
              opacity: 0,
            }}
            src={imgSrc}
            alt="avatar"
            className="size-24 rounded-lg border-2"
          />
        </div>
        <motion.div
          initial={{
            y: 12,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          exit={{
            y: -12,
            opacity: 0,
          }}
        >
          <span className="mb-1.5 block text-3xl font-medium">{name}</span>
          
        </motion.div>
      </div>
      <motion.p
        initial={{
          y: 12,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        exit={{
          y: -12,
          opacity: 0,
        }}
        className="text-xl leading-relaxed"
      >
        {content}
      </motion.p>
    </motion.div>
  </MotionConfig>
);

export const OPTIONS = [
  // const scrapBuyers = [
    {
      title: "Aluminium Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://www.globalirontraders.com/images/services/aluminium-scrap.webp"
          name="Aluminium Scrap Buyer"
          title="CEO"
          company="The Company"
          content="Lightweight and corrosion-resistant, ideal for automotive parts. Sourcing from us ensures consistent quality."
        />
      ),
    },
    {
      title: "Computer Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://www.globalirontraders.com/images/services/computer-scrap.webp"
          name="Computer Scrap Buyer"
       
          content="Rich in precious metals like gold and silver, perfect for recycling. Choose us for reliable supply."
        />
      ),
    },
    {
      title: "Iron Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/dirty-dumped-objects-arrangement_23-2148996942.jpg?t=st=1720108219~exp=1720111819~hmac=7f775584ac06bbdb947f3f47433386cedb7371e0a889b01b95247f8f86443e3c&w=360"
          name="Iron Scrap Buyer"
         
          content="Essential for construction and manufacturing. Our high-grade iron scrap boosts production efficiency."
        />
      ),
    },
    {
      title: "Copper Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://t3.ftcdn.net/jpg/03/76/01/90/240_F_376019027_YYGojOuZ4zWRWnVRRLJWYPLJtUJiqNZF.jpg"
          name="Copper Scrap Buyer"
          
          content="Excellent conductivity for electrical applications. Trust us for pure, high-quality copper scrap"
        />
      ),
    },
    {
      title: "Brass Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/gold-textured-background_53876-15082.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
          name="Brass Scrap Buyer"
       
          content="Perfect for plumbing and fittings, our brass scrap offers durability and strength."
        />
      ),
    },
    {
      title: "Electronic Scraps",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/assortment-dirty-dumped-objects_23-2148996979.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
          name="Electronic Scrap Buyer"
          
          content="Contains valuable materials for recycling. We provide sorted, high-value electronic scrap."
        />
      ),
    },
    {
      title: "Battery Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/high-view-battery-pollution-waste_23-2148764794.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
          name="Battery Scrap Buyer"
        
          content="Essential for lead recovery, we offer environmentally responsible battery scrap solutions."
        />
      ),
    },
    {
      title: "Electrical Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/beans-market-india_1353-68.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
          name="Electrical Scrap Buyer"
         
          content="Rich in copper and aluminum, ideal for recycling. Our scrap ensures efficient material recovery."
        />
      ),
    },
    {
      title: "Lead Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/shattered-roof-tiles_1353-251.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
          name="Lead Scrap Buyer"
         
          content="Key for manufacturing new batteries. Our lead scrap is reliable and high-purity."
        />
      ),
    },
  ];
  
// ];
