import React from 'react';
import DoctorVideo from '../../Assets/Ship_1.mp4';
import FloatingWhatsAppButton from '../../component/FloatingButton/FloatingWhatsAppButton';

const Banner1 = () => {
  return (
    <div className="relative w-full h-[700px] overflow-hidden">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={DoctorVideo}
        autoPlay
        muted
        loop
        playsInline
      />
      <div className="relative z-10 p-8 mt-12">
        {/* Element 1 */}
        <div className="animate-slidein300 opacity-0 mt-12">
          <div className="text-blue-600 text-7xl font-bold mt-5">My Care Trading</div>
        </div>

        {/* Element 2 */}
        <h1 className="animate-slidein300 opacity-0 text-white text-3xl font-bold mb-4 mt-2">
          {/* dfdfdf */}
          Your Trusted Scrap Trading Partner
          
        </h1>

        {/* Element 3 */}
        <p className="animate-slidein500 opacity-0 text-white text-lg mb-4">
          {/* Meet the new standard for modern software development. */}
          <br />
          {/* Streamline issues, sprints, and product roadmaps. */}
        </p>
      </div>
      <FloatingWhatsAppButton />
    </div>
  );
};

export default Banner1;
